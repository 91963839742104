<template>
  <div>
    <!--begin::Compnay Admins-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Compnay Admin Add</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToCompanyAdminList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_company_admin_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company_admin.first_name"
                    :counter="25"
                    :rules="firstNameRules"
                    label="First Name"
                    required
                    outlined
                    dense
                    class="pa-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    v-model="company_admin.last_name"
                    :counter="25"
                    :rules="lastNameRules"
                    label="Last Name"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company_admin.email"
                    label="Email"
                    :rules="emailRules"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="company_type=='Small Enterprise'">
                  <v-text-field
                    class="pa-2"
                    :rules="passwordRules"
                    v-model="company_admin.password"
                    label="Password"
                    :append-icon="pass_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="pass_show ? 'text' : 'password'"
                    @click:append="pass_show = !pass_show"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" v-if="company_type!='Small Enterprise' && subscription_type != 'Free'">
                  <v-select
                    v-model="company_admin.committee_ids"
                    :items="committee_list"
                    label="Select Committee"
                    class="pa-2"
                    :rules="[v => !!v || 'Minimun one Committee is required']"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    outlined
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="company_type=='Small Enterprise'">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company_admin.department"
                    label="Department"
                    required
                    class="pa-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pa-2"
                    :rules="cPasswordRules"
                    v-model="company_admin.cpassword"
                    label="Confirm Password"
                    :append-icon="cpass_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="cpass_show ? 'text' : 'password'"
                    @click:append="cpass_show = !cpass_show"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid || disable_btn"
                  class="ml-5"
                  color="success"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="ml-5"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Compnay Admins-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  STORE_ADMIN,
  UPDATE_ADMIN
} from "@/core/services/store/companyadmin.module";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "companyAdminCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      company_admin: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        cpassword: "",
        department: "",
        user_type: 3,
        is_chapter_user: 0,
        committee_ids: [],
        subscription_type:""
      },
      committee_list: [],
      valid: true,
      disable_btn: false,
      pass_show: false,
      cpass_show: false,
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      passwordRules: [
        v => !!v || "Password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 25 characters",
        v =>
          (v && v.length >= 6) || "Password must be greater than 5 characters"
      ],
      cPasswordRules: [
        v => !!v || "Confirm Password is required",
        v =>
          (v && v.length <= 20) || "Password must be less than 25 characters",
        v =>
          (v && v.length >= 6) || "Password must be greater than 5 characters",
        v =>
          (this.company_admin.password === this.company_admin.cpassword) || "Confirm Password must be same as Password"    
      ],
      edit_form: false,
      company_type: "Small Enterprise",
      subscription_type: "Free"
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany"])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getSubscriptionType: function(newVal) {
      this.subscription_type = newVal ? newVal : "Free";
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "company-admin/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.company_admin) {
            context.company_admin = result.data.company_admin;
            context.company_admin.password = "******";
            context.company_admin.cpassword = "******";
            context.company_admin.committee_ids = result.data.company_admin.committee_ids;
            context.company_admin.department = result.data.company_admin.role;
          } else {
            context.$router.go(-1); //go back to list
            Swal.fire(result.data.error, "", "error");
          }
        },
        function(error) {
          Swal.fire("Error", "Company Admin not found.", "error");
          context.$router.push({ name: "companyAdminList" });
        }
      );
    }

    EventBus.$on("STORE_ADMIN_BUS", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "companyAdminList" });
      } else {
        context.disable_btn = false;
      }
    });

    EventBus.$on("STORE_ADMIN_BUS", function(payLoad) {
      if (payLoad) {
        context.$router.push({ name: "companyAdminList" });
      } else {
        context.disable_btn = false;
      }
    });

    EventBus.$on("UPDATE_ADMIN_ERROR", function(newVal) {
        context.$router.push({ name: "companyAdminList" });
    });

    EventBus.$on("GET_SUBSCRIPTION_TYPE", function(newVal) {
        context.subscription_type = newVal;
    });

    context.getCommitteeList();

    if (this.getCompany && this.getCompany.length > 0) {
      this.company_type = this.getCompany.company_type;
    } else {
      this.$store.dispatch(GET_COMPANY).then(() => {
        this.company_type = this.getCompany.company_type;
      });
    }
    
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Company Admin" }]);

    const company_admin_form = KTUtil.getById("kt_company_admin_form");

    this.fv = formValidation(company_admin_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    updateCompanyAdmin() {
      let context = this;
      this.company_admin["subscription_type"] = context.subscription_type;
      this.$store.dispatch(UPDATE_ADMIN, this.company_admin).then(() => {
        this.$router.go(-1);
        context.disable_btn = false;
      });
    },
    redirectToCompanyAdminList() {
      let context = this;
      context.disable_btn = false;
      this.$router.push({ name: "companyAdminList" });
    },
    submitCompanyAdmin() {
      if (this.company_type == "Chapter Organization") {
        this.company_admin["is_chapter_user"] = 1;
        this.$store.dispatch(STORE_ADMIN, this.company_admin);
      }else{
        this.$store.dispatch(STORE_ADMIN, this.company_admin);
      }
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateCompanyAdmin();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitCompanyAdmin();
      } else {
        this.snackbar = true;
      }
    },
    getCommitteeList() {
      let context = this;
      axios({
        method: "get",
        url: "committee",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.committee) {
            var committee_arr = result.data.committee;
            committee_arr.forEach(committee => {
              context.committee_list.push({name: committee.name, id: committee.id});
            });
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          Swal.fire("Error", "Members not found!", "error");
        }
      );
    }
  }
};
</script>
